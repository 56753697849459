export enum CustomerType {
  company = 'Pessoa Jurídica',
  individual = 'Pessoa Física',
}

export enum CustomerDocumentType {
  CPF = 'CPF',
  CNPJ = 'CNPJ',
  PASSPORT = 'Passaporte',
}

export type CustomerCardBase = {
  paymentProviderCustomerCardId: string;
  commission?: number;
  document: string;
  createdAt: string;
};

export type CustomerAddressBase = {
  paymentProviderAddressId: string;
  city: string;
  country: string;
  line1: string;
  line2: string;
  state: string;
  zipCode: string;
};

export type CustomerPhoneBase = {
  id: string;
  areaCode: string;
  countryCode: string;
  number: string;
  type: 'home' | 'mobile';
};

export type CustomerBase = {
  id: string;
  paymentProviderCustomerId: string;
  birthDate?: string;
  document: string;
  documentType: 'CPF' | 'CNPJ' | 'PASSPORT';
  email: string;
  name: string;
  type: 'individual' | 'company';
  createdAt: string;
};
