import { Box, SimpleGrid, VStack } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import * as Yup from 'yup';
import { MaskedInput } from '../../../../../../components/Form/MaskedInput';
import { ReactSelect } from '../../../../../../components/Form/ReactSelect';

export type JcoinPaymentProviders = 'llloyalty';
export type CardPaymentProviders = 'pagarme' | 'roadpass' | 'zoop';

export type RegisterAppFormData = {
  description?: string;
  name: string;
  ref: string;
  jcoinPaymentProvider?: JcoinPaymentProviders;
  cardPaymentProvider?: CardPaymentProviders;
};
interface IAppProps {
  onJcoinPaymentProviderChange: (jcoinProvider: JcoinPaymentProviders) => void;
  onCardPaymentProviderChange: (cardProvider: CardPaymentProviders) => void;
  requireFields?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSchemaChange: (schema: Yup.ObjectSchema<any>) => void;
  formHook: UseFormReturn;
}

const appRegisterFormSchema = Yup.object().shape({
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  name: Yup.string().required('Requerido'),
  ref: Yup.string().required('Requerido'),
  jcoinPaymentProvider: Yup.string().nullable(),
  cardPaymentProvider: Yup.string(),
});

export const RegisterAppForm = ({
  requireFields,
  onJcoinPaymentProviderChange,
  onCardPaymentProviderChange,
  formHook,
  onSchemaChange,
}: IAppProps): JSX.Element => {
  const jcoinProviderOptions = useMemo(
    () => [
      { label: 'Nenhum', value: undefined },
      { label: 'LL Loyalty', value: 'llloyalty' },
    ],
    [],
  );

  const cardProviderOptions = useMemo(
    () => [
      { label: 'Pagarme', value: 'pagarme' },
      { label: 'Road Pass', value: 'roadpass' },
      { label: 'Zoop', value: 'zoop' },
    ],
    [],
  );

  const { register, formState, control, watch } = formHook;

  const whatchJcoinProviderValue = watch('jcoinPaymentProvider');
  const whatchCardProviderValue = watch('cardPaymentProvider');

  const { errors } = formState;

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const onJcoinChange = useCallback(
    (jcoinProviderValue: any): void => {
      onJcoinPaymentProviderChange(jcoinProviderValue as JcoinPaymentProviders);
    },
    [onJcoinPaymentProviderChange],
  );

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const onCardProviderChange = useCallback(
    (cardProviderValue: any): void => {
      onCardPaymentProviderChange(cardProviderValue as CardPaymentProviders);
    },
    [onCardPaymentProviderChange],
  );

  useEffect(() => {
    onJcoinChange(whatchJcoinProviderValue);
  }, [whatchJcoinProviderValue, onJcoinChange]);

  useEffect(() => {
    onCardProviderChange(whatchCardProviderValue);
  }, [whatchCardProviderValue, onCardProviderChange]);

  useEffect(() => {
    onSchemaChange(
      requireFields ? appRegisterFormSchema : Yup.object().shape({}),
    );
  }, [requireFields]);

  return (
    <Box>
      <VStack spacing="8">
        <MaskedInput label="Nome" error={errors.name} {...register('name')} />

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <MaskedInput
            label="Referência"
            autoComplete="off"
            textTransform="uppercase"
            error={errors.ref}
            {...register('ref')}
          />

          <ReactSelect
            label="Provedor de pagamentos Cartão"
            name="cardPaymentProvider"
            control={control}
            options={cardProviderOptions}
            defaultValue={cardProviderOptions[0]}
            error={errors.cardPaymentProvider}
          />

          <ReactSelect
            label="Provedor de pagamentos de JCoin"
            name="jcoinPaymentProvider"
            control={control}
            options={jcoinProviderOptions}
            defaultValue={jcoinProviderOptions[0]}
            error={errors.jcoinPaymentProvider}
          />
        </SimpleGrid>

        <MaskedInput
          as="textarea"
          minHeight="100px"
          resize="none"
          py="2"
          label="Descrição"
          error={errors.description}
          {...register('description')}
        />
      </VStack>
    </Box>
  );
};
