import { AppBase } from '../../models/app';
import { CustomerBase } from '../../models/customer';
import { OrderBase } from '../../models/order';
import { RecipientBase } from '../../models/recipient';
import api from '../api';

export interface IDetailedOrder extends OrderBase {
  app: AppBase;
  recipient: RecipientBase;
  customer: CustomerBase;
}

export const showOrdersService = async (
  orderId: string,
): Promise<IDetailedOrder> => {
  const { data } = await api.get<IDetailedOrder>(`/orders/${orderId}`);

  return data;
};

export const showOrdersServiceById = async (
  id: string,
): Promise<IDetailedOrder> => {
  const { data } = await api.get<IDetailedOrder>(`/orders/getById/${id}`);

  return data;
};
