import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { RiPencilLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { Pagination } from '../../../../../../../components/Pagination';
import { TableFilters } from '../../../../../../../components/TableFilters';
import { IAppListItem } from '../../../../../../../services/Apps/ListAppsService';

export interface IApp extends IAppListItem {
  formattedDate: string;
}

interface IOnFilterProps {
  filter: string;
  value: string;
}

interface IAppTableProps extends BoxProps {
  apps: IApp[];
  onFilter?: (data: IOnFilterProps) => void;
  defaultFilterBy?: string;
  defaultSortBy?: string;
  onSort?: (sort: 'name' | 'ref' | 'url' | 'createdAt') => void;
  onOrder?: (order: 'ASC' | 'DESC') => void;
  currentPage?: number;
  onPageChange?: (page: number) => void;
  totalPages?: number;
}

export const AppTable = ({
  apps,
  onFilter,
  defaultFilterBy,
  defaultSortBy,
  onSort,
  onOrder,
  currentPage,
  onPageChange,
  totalPages,
  ...rest
}: IAppTableProps): JSX.Element => {
  const handleSort = useCallback(
    (sort: string) => {
      if (onSort) {
        onSort(sort as 'name' | 'ref' | 'url' | 'createdAt');
      }
    },
    [onSort],
  );

  const filterOptions = useMemo(
    () => [
      {
        label: 'Nome',
        value: 'name',
      },
      {
        label: 'Ref',
        value: 'ref',
      },
      {
        label: 'Url',
        value: 'url',
      },
    ],
    [],
  );

  const orderingOptions = useMemo(
    () => [
      {
        label: 'Data de cadastro',
        value: 'createdAt',
      },
      {
        label: 'Nome',
        value: 'name',
      },
      {
        label: 'Ref',
        value: 'ref',
      },
      {
        label: 'Url',
        value: 'url',
      },
    ],
    [],
  );

  return (
    <Box {...rest}>
      <TableFilters
        filterOptions={onFilter ? filterOptions : undefined}
        orderingOptions={orderingOptions}
        onFilter={onFilter}
        defaultFilterBy={defaultFilterBy}
        defaultSortBy={defaultSortBy}
        onSort={handleSort}
        onOrder={onOrder}
      />

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th>App</Th>
            <Th>Url</Th>
            <Th>Data de cadastro</Th>
            <Th w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {apps.map((app) => (
            <Tr key={app.id}>
              <Td>
                <Box>
                  <Text fontWeight="bold">{app.name}</Text>
                  <Text fontSize="sm" color="gray.500">
                    {`Ref: ${app.ref}`}
                  </Text>
                </Box>
              </Td>

              <Td>{app.formattedDate}</Td>

              <Td>
                <LinkButton
                  icon={<Icon as={RiPencilLine} fontSize="16" />}
                  to={{
                    pathname: '/apps/details',
                    state: {
                      appId: app.id,
                    },
                  }}
                >
                  Detalhes
                </LinkButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};
