import {
  Box,
  Divider,
  Heading,
  HStack,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import * as Yup from 'yup';

import 'react-datepicker/dist/react-datepicker.css';
import { MaskedInput } from '../../../../../../components/Form/MaskedInput';
import { ReactSelect } from '../../../../../../components/Form/ReactSelect';

type BankAccountRequest = {
  accountCheckDigit: string;
  accountNumber: string;
  bank: string;
  branchCheckDigit?: string;
  branchNumber: string;
  holderDocument: string;
  holderName: string;
  holderType: 'individual' | 'company';
  metadata?: {
    [key: string]: string;
  };
  type: 'checking' | 'savings';
};

export type AppSplitRecipientData = {
  bankAccount: BankAccountRequest;
  description?: string;
  document: string;
  email: string;
  name: string;
  type: 'individual' | 'company';
  hookUrl: string;
  paymentProviderPublicKey: string;
  paymentProviderSecretKey: string;
};

export type AppSplitRecipientFormData = {
  accountCheckDigit: string;
  accountNumber: string;
  accountType: 'checking' | 'savings';
  bank: string;
  branchCheckDigit?: string;
  branchNumber: string;
  description?: string;
  document: string;
  email: string;
  holderDocument: string;
  holderName: string;
  holderType: 'individual' | 'company';
  name: string;
  recipientType: 'individual' | 'company';
  hookUrl: string;
  paymentProviderPublicKey: string;
  paymentProviderSecretKey: string;
};

interface ISplitRecipientProps {
  splitRecipient?: AppSplitRecipientData;
  requireFields?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSchemaChange: (schema: Yup.ObjectSchema<any>) => void;
  formHook: UseFormReturn;
}

const splitRecipientRegisterFormSchema = Yup.object().shape({
  accountCheckDigit: Yup.string().required('Requerido'),
  accountNumber: Yup.string().required('Requerido'),
  accountType: Yup.string().required('Requerido'),
  bank: Yup.string().required('Requerido'),
  branchCheckDigit: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  branchNumber: Yup.string().required('Requerido'),
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  document: Yup.string().required('Requerido'),
  email: Yup.string().required('Requerido'),
  holderDocument: Yup.string().required('Requerido'),
  holderName: Yup.string().required('Requerido'),
  holderType: Yup.string().required('Requerido'),
  name: Yup.string().required('Requerido'),
  recipientType: Yup.string().required('Requerido'),
  hookUrl: Yup.string()
    .url('Inválido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  paymentProviderPublicKey: Yup.string().required('Requerido'),
  paymentProviderSecretKey: Yup.string().required('Requerido'),
});

export const AppSplitRecipientForm = ({
  splitRecipient,
  requireFields,
  formHook,
  onSchemaChange,
}: ISplitRecipientProps): JSX.Element => {
  const accountTypeOptions = useMemo(
    () => [
      { label: 'Conta Corrente', value: 'checking' },
      { label: 'Conta Poupança', value: 'savings' },
    ],
    [],
  );

  const recipientTypeOptions = useMemo(
    () => [
      { label: 'Pessoa Física', value: 'individual' },
      { label: 'Pessoa Jurídica', value: 'company' },
    ],
    [],
  );

  const { register, formState, control, reset } = formHook;

  useEffect(() => {
    if (splitRecipient) {
      const { bankAccount, ...rest } = splitRecipient;

      reset({
        ...rest,
        ...bankAccount,
        accountType: bankAccount.type,
        recipientType: rest.type,
      });
    }
  }, [reset, splitRecipient]);

  const { errors: errorsform } = formState;

  const errors = errorsform.splitRecipient;

  useEffect(() => {
    onSchemaChange(
      requireFields ? splitRecipientRegisterFormSchema : Yup.object().shape({}),
    );
  }, [requireFields]);

  return (
    <Box>
      <VStack spacing="8">
        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <MaskedInput
            label="Chave pública do provedor de pagamentos"
            autoComplete="off"
            error={errors?.paymentProviderPublicKey}
            {...register('splitRecipient.paymentProviderPublicKey')}
          />

          <MaskedInput
            label="Chave secreta do provedor de pagamentos"
            autoComplete="off"
            error={errors?.paymentProviderSecretKey}
            {...register('splitRecipient.paymentProviderSecretKey')}
          />

          <MaskedInput
            label="API Hook Url"
            autoComplete="off"
            type="hookUrl"
            error={errors?.hookUrl}
            {...register('splitRecipient.hookUrl')}
          />
        </SimpleGrid>
        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <MaskedInput
            label="Nome"
            error={errors?.name}
            {...register('splitRecipient.name')}
          />

          <MaskedInput
            label="E-mail"
            error={errors?.email}
            {...register('splitRecipient.email')}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <MaskedInput
            label="Documento"
            error={errors?.document}
            {...register('splitRecipient.document')}
          />

          <ReactSelect
            label="Tipo do recebedor"
            name="splitRecipient.recipientType"
            control={control}
            options={recipientTypeOptions}
            error={errors?.recipientType}
          />
        </SimpleGrid>

        <MaskedInput
          as="textarea"
          minHeight="100px"
          resize="none"
          py="2"
          label="Descrição"
          error={errors?.description}
          {...register('splitRecipient.description')}
        />
      </VStack>

      <Heading mt="8" size="md" fontWeight="normal">
        Conta de recebimento
      </Heading>

      <Divider my="6" />

      <VStack spacing="8" align="left">
        <MaskedInput
          label="Titular"
          error={errors?.holderName}
          {...register('splitRecipient.holderName')}
        />

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <HStack spacing="4">
            <MaskedInput
              label="Documento"
              error={errors?.holderDocument}
              {...register('splitRecipient.holderDocument')}
            />

            <ReactSelect
              label="Tipo do titular"
              name="splitRecipient.holderType"
              control={control}
              options={recipientTypeOptions}
              error={errors?.holderType}
            />
          </HStack>

          <HStack spacing="4">
            <ReactSelect
              label="Tipo da conta"
              name="splitRecipient.accountType"
              control={control}
              options={accountTypeOptions}
              error={errors?.accountType}
            />

            <MaskedInput
              label="Número do banco"
              error={errors?.bank}
              {...register('splitRecipient.bank')}
            />
          </HStack>
        </SimpleGrid>

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <HStack spacing="4">
            <MaskedInput
              label="Número da agência"
              error={errors?.branchNumber}
              {...register('splitRecipient.branchNumber')}
            />

            <MaskedInput
              label="Dígito"
              error={errors?.branchCheckDigit}
              {...register('splitRecipient.branchCheckDigit')}
            />
          </HStack>

          <HStack spacing="4">
            <MaskedInput
              label="Número da conta"
              error={errors?.accountNumber}
              {...register('splitRecipient.accountNumber')}
            />
            <MaskedInput
              label="Dígito"
              error={errors?.accountCheckDigit}
              {...register('splitRecipient.accountCheckDigit')}
            />
          </HStack>
        </SimpleGrid>
      </VStack>
    </Box>
  );
};
