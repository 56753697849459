import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  Icon,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useCallback, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { AnyObjectSchema } from 'yup';
import { createAppsService } from '../../../../../services/Apps/CreateAppsService';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import {
  AppLLLoyaltyConfigData,
  AppLLLoyaltyConfigForm,
} from '../components/AppLLLoyaltyConfigForm';
import {
  AppRoadPassConfigData,
  AppRoadPassConfigForm,
} from '../components/AppRoadPassConfigurationForm';
import {
  AppSplitRecipientData,
  AppSplitRecipientForm,
  AppSplitRecipientFormData,
} from '../components/AppSplitRecipientForm';
import {
  AppZoopConfigData,
  AppZoopConfigForm,
} from '../components/AppZoopConfigurationForm';
import {
  CardPaymentProviders,
  JcoinPaymentProviders,
  RegisterAppForm,
  RegisterAppFormData,
} from '../components/RegisterAppForm';

interface IApp extends RegisterAppFormData {
  splitRecipient?: AppSplitRecipientData;
  llloyaltyConfiguration?: AppLLLoyaltyConfigData;
  roadPassConfiguration?: AppRoadPassConfigData;
  zoopConfiguration?: AppZoopConfigData;
}

interface ICreateAppProps extends RegisterAppFormData {
  splitRecipient?: AppSplitRecipientFormData;
  llloyaltyConfiguration?: AppLLLoyaltyConfigData;
  roadPassConfiguration?: AppRoadPassConfigData;
  zoopConfiguration?: AppZoopConfigData;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeEmptyEntries = (obj: any): any => {
  const formattedObj = Object.entries(obj)
    .filter(([, v]) => v !== '' && v !== null && v !== undefined)
    .reduce(
      (acc, [k, v]) => ({
        ...acc,
        [k]: v === Object(v) ? removeEmptyEntries(v) : v,
      }),
      {},
    );

  return Object.keys(formattedObj).length > 0 ? formattedObj : null;
};

export const AppRegister = (): JSX.Element => {
  const { push } = useHistory();
  const toast = useToast();

  const [app, setApp] = useState<IApp>();
  const [jcoinProvider, setJcoinProvider] = useState<JcoinPaymentProviders>();
  const [cardProvider, setCardProvider] =
    useState<CardPaymentProviders>('pagarme');

  const [llFormSchema, setLlFormSchema] = useState<AnyObjectSchema>();
  const [appFormSchema, setAppFormSchema] = useState<AnyObjectSchema>();
  const [pagarmeFormSchema, setPagarmeFormSchema] = useState<AnyObjectSchema>();
  const [zoopFormSchema, setZoopFormSchema] = useState<AnyObjectSchema>();
  const [roadpassFormSchema, setRoadpassFormSchema] =
    useState<AnyObjectSchema>();

  const formHook = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        ...appFormSchema?.fields,
        /* eslint-disable-next-line */
        splitRecipient: Yup.object(pagarmeFormSchema?.fields),
        /* eslint-disable-next-line */
        zoopConfiguration: Yup.object(zoopFormSchema?.fields),
        /* eslint-disable-next-line */
        roadPassConfiguration: Yup.object(roadpassFormSchema?.fields),
        /* eslint-disable-next-line */
        llloyaltyConfiguration: Yup.object(llFormSchema?.fields),
      }),
    ),
    defaultValues: {
      cardPaymentProvider: 'pagarme',
      /* eslint-disable @typescript-eslint/no-explicit-any */
    } as any,
  });

  const { handleSubmit, formState } = formHook;

  const onJcoinChange = useCallback(
    (jcoinProviderValue: JcoinPaymentProviders) => {
      setJcoinProvider(jcoinProviderValue);
    },
    [],
  );

  const onCardProviderChange = useCallback(
    (cardProviderValue: CardPaymentProviders) => {
      setCardProvider(cardProviderValue);
    },
    [],
  );

  const handleSubmitApp = useCallback(
    async (props: ICreateAppProps) => {
      try {
        const {
          splitRecipient,
          zoopConfiguration,
          llloyaltyConfiguration,
          roadPassConfiguration,
          ...appRest
        } = removeEmptyEntries(props);

        /* eslint-disable @typescript-eslint/no-explicit-any */
        const createAppProps: any = {
          ...appRest,
        };

        if (splitRecipient) {
          createAppProps.splitRecipient = {
            document: splitRecipient.document,
            email: splitRecipient.email,
            name: splitRecipient.name,
            type: splitRecipient.recipientType,
            description: splitRecipient.description,
            hookUrl: splitRecipient.hookUrl,
            paymentProviderPublicKey: splitRecipient.paymentProviderPublicKey,
            paymentProviderSecretKey: splitRecipient.paymentProviderSecretKey,
            default_bank_account: {
              account_check_digit: splitRecipient.accountCheckDigit,
              account_number: splitRecipient.accountNumber,
              bank: splitRecipient.bank,
              branch_check_digit: splitRecipient.branchCheckDigit,
              branch_number: splitRecipient.branchNumber,
              holder_document: splitRecipient.holderDocument,
              holder_name: splitRecipient.holderName,
              holder_type: splitRecipient.holderType,
              type: splitRecipient.accountType,
            },
          };
        }

        if (roadPassConfiguration) {
          createAppProps.roadPassConfiguration = { ...roadPassConfiguration };
        }

        if (llloyaltyConfiguration) {
          createAppProps.llloyaltyConfiguration = { ...llloyaltyConfiguration };
        }

        if (zoopConfiguration) {
          createAppProps.zoopConfiguration = { ...zoopConfiguration };
        }

        const newApp = await createAppsService(createAppProps);

        setApp(newApp);

        push('/apps/details', { appId: newApp.id });

        toast({
          title: 'Cadastrado com sucesso',
          description: 'O app foi cadastrado corretamente!',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao cadastrar',
            description: 'Ocorreu um erro ao cadastrar app, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [push, toast],
  );

  return (
    <DefaultLayout>
      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleSubmitApp)}
      >
        <Heading size="lg" fontWeight="normal">
          Cadastrar App
        </Heading>
        <Divider my="6" borderColor="gray.300" />

        <RegisterAppForm
          onJcoinPaymentProviderChange={onJcoinChange}
          onCardPaymentProviderChange={onCardProviderChange}
          onSchemaChange={setAppFormSchema}
          formHook={formHook}
          requireFields
        />

        <Divider my="6" borderColor="gray.300" />
        <Heading size="md" fontWeight="normal" marginBlock={2}>
          Gateways de Pagamento
        </Heading>
        <Box borderWidth="1px" borderRadius="lg">
          <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Configurações Pagarme
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={10} pt={4}>
                <AppSplitRecipientForm
                  splitRecipient={app?.splitRecipient}
                  onSchemaChange={setPagarmeFormSchema}
                  formHook={formHook}
                  requireFields={cardProvider === 'pagarme'}
                />
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Configurações Roadpass
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={10} pt={4}>
                <AppRoadPassConfigForm
                  onSchemaChange={setRoadpassFormSchema}
                  formHook={formHook}
                  requireFields={cardProvider === 'roadpass'}
                />
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Configurações Zoop
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={10} pt={4}>
                <AppZoopConfigForm
                  onSchemaChange={setZoopFormSchema}
                  formHook={formHook}
                  requireFields={cardProvider === 'zoop'}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>

        <Divider my="6" borderColor="gray.300" />
        <Heading size="md" fontWeight="normal" marginBlock={2}>
          Provedores de JCoins
        </Heading>
        <Box borderWidth="1px" borderRadius="lg">
          <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem pb={10} pt={4}>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Configurações LLLoyalty
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <AppLLLoyaltyConfigForm
                  onSchemaChange={setLlFormSchema}
                  formHook={formHook}
                  requireFields={jcoinProvider === 'llloyalty'}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>

        <Divider my="6" borderColor="gray.300" />
        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            {/* <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button> */}
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
              <Icon as={FiSave} fontSize="20" ml="2" />
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
