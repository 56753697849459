import api from '../api';
import {
  AppBase,
  AppSplitRecipientBankAccountBase,
  AppSplitRecipientBase,
} from '../../models/app';
import { AppLLLoyaltyConfigData } from '../../pages/App/General/Apps/components/AppLLLoyaltyConfigForm';
import { AppRoadPassConfigData } from '../../pages/App/General/Apps/components/AppRoadPassConfigurationForm';
import { AppZoopConfigData } from '../../pages/App/General/Apps/components/AppZoopConfigurationForm';

type BankAccountRequest = {
  account_check_digit: string;
  account_number: string;
  bank: string;
  branch_check_digit?: string;
  branch_number: string;
  holder_document: string;
  holder_name: string;
  holder_type: 'individual' | 'company';
  metadata?: {
    [key: string]: string;
  };
  type: 'checking' | 'savings';
};

type TransferSettings = {
  transfer_enabled: boolean;
  transfer_interval: 'day';
  transfer_day: number;
};

type AnticipationSettings = {
  delay?: string;
  enabled: boolean;
  type: 'full' | '1025';
  volume_percentage: string;
};

type CreateRecipientProps = {
  automatic_anticipation_settings?: AnticipationSettings;
  code?: string;
  default_bank_account: BankAccountRequest;
  description?: string;
  document: string;
  email: string;
  metadata?: {
    [key: string]: string;
  };
  name: string;
  transfer_settings?: TransferSettings;
  type: 'individual' | 'company';
  hookUrl?: string;
  paymentProviderPublicKey: string;
  paymentProviderSecretKey: string;
};

interface ICreateAppProps {
  description?: string;
  name: string;
  ref: string;
  jcoinPaymentProvider?: string;
  cardPaymentProvider?: string;
  llloyaltyConfiguration?: AppLLLoyaltyConfigData;
  roadPassConfiguration?: AppRoadPassConfigData;
  splitRecipient?: CreateRecipientProps;
  zoopConfiguration?: AppZoopConfigData;
}

interface IDetailedAppSplitRecipient extends AppSplitRecipientBase {
  bankAccount: AppSplitRecipientBankAccountBase;
}

export interface INewApp extends AppBase {
  splitRecipient: IDetailedAppSplitRecipient;
}

export const createAppsService = async ({
  description,
  name,
  ref,
  splitRecipient,
  llloyaltyConfiguration,
  jcoinPaymentProvider,
  cardPaymentProvider,
  roadPassConfiguration,
  zoopConfiguration,
}: ICreateAppProps): Promise<INewApp> => {
  const {
    paymentProviderPublicKey,
    paymentProviderSecretKey,
    hookUrl,
    ...splitRecipientRest
  } = (splitRecipient || {}) as CreateRecipientProps;

  let splitRecipientData;
  if (Object.keys(splitRecipientRest).length === 0) {
    splitRecipientData = undefined;
  } else {
    splitRecipientData = splitRecipientRest;
  }

  const { data } = await api.post<INewApp>('/apps', {
    description,
    name,
    ref,
    paymentProviderPublicKey,
    paymentProviderSecretKey,
    jcoinPaymentProvider,
    cardPaymentProvider,
    hookUrl,
    splitRecipient: splitRecipientData,
    llloyaltyConfiguration,
    roadPassConfiguration,
    zoopConfiguration,
  });

  return data;
};
