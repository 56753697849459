import { Box, Heading, Flex, useToast, Icon } from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { LinkButton } from '../../../../../components/LinkButton';
import { maskShortDate } from '../../../../../utils/formatters/handleMask';
import { AppTable, IApp } from './components/AppTable';
import { listAppsService } from '../../../../../services/Apps/ListAppsService';

interface IFilter {
  filter: string;
  value: string;
}

interface ILoadAppsProps {
  name?: string;
  sort?: 'name' | 'ref' | 'url' | 'createdAt';
  order?: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
}

export const AppList = (): JSX.Element => {
  const toast = useToast();

  const [appsList, setAppsList] = useState<IApp[]>([]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentSort, setCurrentSort] = useState<
    'name' | 'ref' | 'url' | 'createdAt'
  >();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();

  const loadApps = useCallback(
    async ({
      name,
      page,
      sort = 'createdAt',
      order = 'ASC',
    }: ILoadAppsProps) => {
      try {
        const apps = await listAppsService({
          name: name || undefined,
          page,
          sort,
          order,
        });

        const appsWithformattedDate = apps.items.map((app) => ({
          ...app,
          formattedDate: maskShortDate(app.createdAt),
        }));

        setTotalPages(apps.pages);
        setAppsList(appsWithformattedDate);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os dados dos apps, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  const handleFilter = useCallback(
    ({ filter, value }: IFilter) => {
      setCurrentFilters({ [filter]: value });

      loadApps({ [filter]: value });
    },
    [loadApps],
  );

  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback(
    (sort: 'name' | 'ref' | 'url' | 'createdAt') => {
      setCurrentSort(sort);
    },
    [],
  );

  const handleOrderList = useCallback(
    (order: 'ASC' | 'DESC') => {
      loadApps({
        ...currentFilters,
        page: currentPage,
        sort: currentSort,
        order,
      });
    },
    [currentFilters, currentPage, currentSort, loadApps],
  );

  useEffect(() => {
    loadApps({
      ...currentFilters,
      page: currentPage,
      sort: currentSort,
    });
  }, [currentFilters, currentPage, currentSort, loadApps]);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Apps
          </Heading>

          <LinkButton
            colorScheme="green"
            icon={<Icon as={RiAddLine} fontSize="16" />}
            to="/apps/register"
          >
            Cadastrar novo
          </LinkButton>
        </Flex>

        <AppTable
          mt="4"
          apps={appsList}
          onFilter={debounceHandleFilter}
          defaultFilterBy="name"
          defaultSortBy="createdAt"
          onSort={handleSortList}
          onOrder={handleOrderList}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
