import { Box, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import * as Yup from 'yup';
import { MaskedInput } from '../../../../../../components/Form/MaskedInput';

export type AppRoadPassConfigData = {
  tenantKey: string;
};

interface IAppRoadPassConfigProps {
  requireFields?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSchemaChange: (schema: Yup.ObjectSchema<any>) => void;
  formHook: UseFormReturn;
}

export type RegisterRoadPassConfigurationFormData = {
  tenantKey: string;
};

const roadpassConfigFormSchema = Yup.object().shape({
  tenantKey: Yup.string().required('Requerido'),
});

export const AppRoadPassConfigForm = ({
  requireFields,
  formHook,
  onSchemaChange,
}: IAppRoadPassConfigProps): JSX.Element => {
  const { register, formState } = formHook;

  const { errors: errorsform } = formState;

  const errors = errorsform.roadPassConfiguration;

  useEffect(() => {
    onSchemaChange(
      requireFields ? roadpassConfigFormSchema : Yup.object().shape({}),
    );
  }, [requireFields]);

  return (
    <Box>
      <VStack spacing="8">
        <MaskedInput
          label="Tenant Key"
          error={errors?.tenantKey}
          {...register('roadPassConfiguration.tenantKey')}
        />
      </VStack>
    </Box>
  );
};
