export const maskArea = (value: string | number): string | number => {
  const match = String(value).replace(/\D/g, '').length;

  if (match) {
    const formatted = `${String(value)
      .replace(/\D/g, '')
      .replace(/(\d)(\d{2})$/, '$1,$2')
      .replace(/(?=(\d{3})+(\D))\B/g, '.')} m²`;

    return formatted;
  }

  return value;
};

export const maskCpf = (value: string | number): string | number => {
  const match = String(value).match(/^(\d{3})(\d{3})(\d{3})(\d{2})/);

  if (match) {
    return `${match[1]}.${match[2]}.${match[3]}-${match[4]}`;
  }
  return value;
};

export const maskCnpj = (value: string | number): string | number => {
  const match = String(value).match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/);

  if (match) {
    return `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}`;
  }
  return value;
};

export const maskZipCode = (value: string | number): string | number => {
  const match = String(value).match(/^(\d{2})(\d{3})(\d{3})/);

  if (match) {
    return `${match[1]}.${match[2]}-${match[3]}`;
  }
  return value;
};

export const maskIdCard = (value: string | number): string | number => {
  const match = String(value).match(/^(\d{2})(\d{3})(\d{3})/);

  if (match) {
    return `${match[1]}.${match[2]}.${match[3]}`;
  }

  return value;
};

export const maskMoney = (value: string | number): string => {
  const match = String(value).replace(/\D/g, '').length;

  if (match) {
    const formatted = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(Number(value) / 100);

    return formatted;
  }

  return '';
};

export const maskShortDate = (date: string): string => {
  const formatted = new Date(date).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return formatted;
};

export const maskDate = (date: string): string => {
  const formatted = new Date(date).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });

  return formatted;
};

export const maskDateTime = (date: string): string => {
  const formatted = new Date(date).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

  return formatted;
};

export const maskDateTimeWithSeconds = (date: string): string => {
  const formatted = new Date(date).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  return formatted;
};

export const maskPhone = (value: string | number): string | number => {
  const match = String(value).match(/^(\d{2})(\d{5}|\d{4})(\d{4})/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return value;
};

export const unmaskNumber = (
  maskedValue: string | number | null,
): number | null => {
  const stringValue = String(maskedValue).replace(/\D/g, '');

  return stringValue ? Number(stringValue) : null;
};
