import api from '../api';
import { RecipientBase } from '../../models/recipient';
import { AppBase } from '../../models/app';

interface IListRecipientProps {
  appName?: string;
  email?: string;
  name?: string;
  sort?: 'name' | 'commission' | 'email' | 'type' | 'createdAt';
  order?: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
}
export interface IRecipientListItem extends RecipientBase {
  app: AppBase;
}

export interface IRecipientList {
  items: IRecipientListItem[];
  pages: number;
  total: number;
}

export const listRecipientsService = async (
  filters?: IListRecipientProps,
): Promise<IRecipientList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          appName: filters.appName || undefined,
          email: filters.email || undefined,
          limit: filters.limit,
          name: filters.name || undefined,
          order: filters.order,
          page: filters.page,
          sort: filters.sort,
        }
      : undefined;

  const { data } = await api.get<IRecipientList>('/recipients', { params });

  return data;
};
