import { useLocation } from 'react-router-dom';
import {
  Box,
  Heading,
  Flex,
  useToast,
  Divider,
  SimpleGrid,
  Text,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tag,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { CheckCircleIcon, WarningTwoIcon } from '@chakra-ui/icons';
import ReactJson from 'react-json-view';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import {
  maskCnpj,
  maskCpf,
  maskDate,
  maskDateTime,
  maskDateTimeWithSeconds,
  maskMoney,
} from '../../../../../utils/formatters/handleMask';
import {
  IDetailedOrder,
  showOrdersServiceById,
} from '../../../../../services/Orders/ShowOrdersService';
import { OrderLog } from '../../../../../models/orderLog';
import { getOrderLogsService } from '../../../../../services/Orders/GetOrderLogsService';

interface IOrder extends IDetailedOrder {
  formattedDate: string;
}

interface ILocationState {
  orderId: string;
}

enum OrderLogOperationEnum {
  order_creation_started = 'Transação iniciada',
  order_authorize_creation = 'Autorização da Transação',
  order_jcoin_redeem = 'Resgate de Pontos para pagamento',
  order_value_validation = 'Validação do valor da transação',
  order_capture_transaction = 'Captura da Transação',
  order_transaction_cancel = 'Cancelamento da Transação',
  order_jcoin_redeem_cancel = 'Cancelamento do resgate',
  order_transaction_complete = 'Transação bem sucedida',
  order_jcoin_installment_earn_cancel = 'Cancelamento do Crédito de Jcoins adquiridos na parcela',
  order_jcoin_earn_cancel = 'Cancelamento do Crédito de Jcoins',
  jcoin_redeem = 'Resgate de JCoins',
  webhook_bill_paid_start = 'Webhook - Fatura paga - Processo iniciado',
  webhook_bill_paid_check_ll_id = 'Webhook - Fatura paga - Checagem do LL ID',
  webhook_bill_paid_confirm_credit = 'Webhook - Fatura paga - Confirmação de crédito',
  webhook_llloyalty_credit_check_start = 'Webhook - Checagem de crédito - Processo iniciado',
  webhook_llloyalty_credit_check_details = 'Webhook - Checagem de crédito - Detalhes',
  webhook_llloyalty_credit_check_jhsf_id_integration = 'Webhook - Checagem de crédito - Integração com JHSF ID',
  webhook_new_payment_start = 'Webhook - Novo pagamento pela Roadpass- Processo iniciado',
  webhook_new_payment_credit_jcoins = 'Webhook - Novo pagamento pela Roadpass - Crédito de JCoins',
  webhook_new_payment_send_payment_to_jhsf_id = 'Webhook - Novo pagamento pela Roadpass - Integração com JHSF ID',
  order_failed_roadpass_transaction_retry_create = 'Iniciando Tentativa de recriar a transação na RoadPass',
  order_failed_roadpass_transaction_retry_capture = 'Iniciando Tentativa de capturar novamente a transação na RoadPass',
  order_failed_roadpass_transaction_send_jhsid = 'Enviando ID da transação para o JHSFID',
  new_payment_zoop_start = 'Novo pagamento pela Zoop - Processo iniciado',
  new_payment_zoop_credit_jcoins = 'Novo pagamento pela Zoop - Crédito de Jcoins',
  new_payment_zoop_send_payment_to_jhsf_id = 'Novo pagamento pela Zoop - Integração com JHSF ID',
  order_zoop_transaction_creation = 'Transação criada na Zoop',
  order_zoop_transaction_cancel = 'Cancelamento da Transação na Zoop',
}

export const OrderDetails = (): JSX.Element => {
  const toast = useToast();
  const {
    state: { orderId },
  } = useLocation<ILocationState>();

  const [order, setOrder] = useState<IOrder>();
  const [orderLogs, setOrderLogs] = useState<OrderLog[]>();

  useEffect(() => {
    async function loadOrder(): Promise<void> {
      try {
        const orderData = await showOrdersServiceById(orderId);

        const orderWithformattedDate = {
          ...orderData,
          formattedDate: maskDate(orderData.createdAt),
        };

        setOrder(orderWithformattedDate);
        try {
          setOrderLogs(await getOrderLogsService(orderId));
        } catch (e) {
          // eslint-disable-next-line
          console.log('Falha ao carregar logs', e);
        }
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os detalhes do pedidos, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadOrder();
  }, [orderId, toast]);
  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes do pedido
          </Heading>
        </Flex>

        {order && (
          <Flex direction="column">
            <SimpleGrid>
              <Text>
                Valor:
                <Text as="span" color="gray.500" ml="2">
                  {maskMoney(order.amount)}
                </Text>
              </Text>
              {order.installments && (
                <Text>
                  Parcelas:
                  <Text as="span" color="gray.500" ml="2">
                    {order.installments}
                  </Text>
                </Text>
              )}
              <Text>
                JCoins:
                <Text as="span" color="gray.500" ml="2">
                  {order.jcoins || 0}
                </Text>
              </Text>
              <Text>
                APP:
                <Text as="span" color="gray.500" ml="2">
                  {order.app.name}
                </Text>
              </Text>
              {order.cardPaymentProvider && (
                <Text>
                  Gateway de pagamento:
                  <Text as="span" color="gray.500" ml="2">
                    {order.cardPaymentProvider}
                  </Text>
                </Text>
              )}

              {order.source && order.source !== 'na' && (
                <Text>
                  Origem:
                  <Text as="span" color="gray.500" ml="2">
                    {
                      {
                        app: 'App JHSF Id',
                        credit_card: 'Cartão de Crédito Físico',
                      }[order.source]
                    }
                  </Text>
                </Text>
              )}

              <Text>
                Nota Fiscal emitida:
                <Text as="span" color="gray.500" ml="2">
                  {order.invoiceIssued ? 'Sim' : 'Não'}
                </Text>
              </Text>
              <Text>
                Transação Internacional:
                <Text as="span" color="gray.500" ml="2">
                  {order.isInternational ? 'Sim' : 'Não'}
                </Text>
              </Text>
              <Text>
                Cartão Virtual:
                <Text as="span" color="gray.500" ml="2">
                  {order.isVirtualCard ? 'Sim' : 'Não'}
                </Text>
              </Text>
              <Text>
                Cartão Adicional:
                <Text as="span" color="gray.500" ml="2">
                  {order.isAdditionalCard ? 'Sim' : 'Não'}
                </Text>
              </Text>
              {order.invoiceDate && (
                <Text>
                  Data da Nota Fiscal:
                  <Text as="span" color="gray.500" ml="2">
                    {maskDateTime(order.invoiceDate)}
                  </Text>
                </Text>
              )}
              {order.roadPassOrderDetails &&
                (order.roadPassOrderDetails.authorizeFailed ||
                  order.roadPassOrderDetails.captureFailed ||
                  order.roadPassOrderDetails.cancelFailed) && (
                  <Alert status="error">
                    <AlertIcon />
                    <Box>
                      <AlertTitle>Atenção!</AlertTitle>
                      <AlertDescription>
                        <SimpleGrid>
                          <Text>
                            Houve um problema nesse pedido na(s) seguinte(s)
                            etapa(s):
                            <Text as="span" color="red.500" ml="2">
                              {[
                                order.roadPassOrderDetails.authorizeFailed &&
                                  'Autorização',
                                order.roadPassOrderDetails.captureFailed &&
                                  'Captura',
                                order.roadPassOrderDetails.cancelFailed &&
                                  'Cancelamento',
                              ]
                                .filter((e) => e)
                                .join(', ')}
                            </Text>
                          </Text>
                          {order.amount === 0 && (order.jcoins || 0) > 0 && (
                            <Text>
                              Essa transação foi concluída mas não enviada para
                              a roadpass, a mesma foi colocada na fila para
                              tentativas de reenvio. É necessário acompanhar
                              manualmente o sucesso do reenvio
                            </Text>
                          )}
                        </SimpleGrid>
                      </AlertDescription>
                    </Box>
                  </Alert>
                )}
            </SimpleGrid>
            {order.recipient && (
              <>
                <Divider my="6" />
                <Heading mt="8" size="md" fontWeight="normal">
                  Recebedor
                </Heading>
                <SimpleGrid>
                  <Text>
                    Nome:
                    <Text as="span" color="gray.500" ml="2">
                      {order.recipient?.name}
                    </Text>
                  </Text>
                  <Text>
                    CNPJ:
                    <Text as="span" color="gray.500" ml="2">
                      {maskCnpj(order.recipient.document)}
                    </Text>
                  </Text>
                </SimpleGrid>
              </>
            )}
            {!order.recipient && order.externalRecipientName && (
              <>
                <Divider my="6" />
                <Heading mt="8" size="md" fontWeight="normal">
                  Recebedor
                </Heading>
                <SimpleGrid>
                  <Text>
                    Nome:
                    <Text as="span" color="gray.500" ml="2">
                      {order.externalRecipientName}
                    </Text>
                  </Text>
                  <Text>
                    CNPJ:
                    <Text as="span" color="gray.500" ml="2">
                      {maskCnpj(order.externalRecipientDocument)}
                    </Text>
                  </Text>
                  <Text>
                    <Tag colorScheme="yellow">
                      Não cadastrado no JHSF PAY - compra realizada em um
                      estabelecimento não parceiro do JHSF ID
                    </Tag>
                  </Text>
                </SimpleGrid>
              </>
            )}
            <Divider my="6" />
            <Heading mt="8" size="md" fontWeight="normal">
              Cliente
            </Heading>
            <SimpleGrid>
              <Text>
                Nome:
                <Text as="span" color="gray.500" ml="2">
                  {order.customer.name}
                </Text>
              </Text>
              <Text>
                Documento:
                <Text as="span" color="gray.500" ml="2">
                  {maskCpf(order.customer.document)}
                </Text>
              </Text>
              <Text>
                Email:
                <Text as="span" color="gray.500" ml="2">
                  {order.customer.email}
                </Text>
              </Text>
            </SimpleGrid>
            <Divider my="6" />
            {order.installmentsData && order.installmentsData.length > 0 && (
              <>
                <Heading mt="8" size="md" fontWeight="normal">
                  Parcelas Pagas
                </Heading>
                <SimpleGrid>
                  <Table colorScheme="blue" mt="4" width="40%">
                    <Thead>
                      <Tr>
                        <Th>Parcela</Th>
                        <Th>Valor</Th>
                        <Th>Data do pagamento</Th>
                      </Tr>
                    </Thead>

                    <Tbody>
                      {order.installmentsData.map((installment) => (
                        <Tr key={installment.id}>
                          <Td>
                            <Box>
                              <Text fontWeight="bold">
                                {installment.installment}
                              </Text>
                            </Box>
                          </Td>
                          <Td>{maskMoney(installment.amount)}</Td>
                          <Td>{maskDateTime(installment.createdAt)}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </SimpleGrid>
                <Divider my="6" />
              </>
            )}

            {orderLogs && (
              <>
                <Heading mt="8" size="md" fontWeight="normal">
                  Logs do pedido
                </Heading>
                <SimpleGrid>
                  <Table colorScheme="blue" mt="4" width="100%">
                    <Thead>
                      <Tr>
                        <Th>Operação/Etapa</Th>
                        <Th>Sucesso</Th>
                        <Th>Resultado</Th>
                        <Th>Data</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {orderLogs.map((log) => (
                        <Tr key={log.id}>
                          <Td>
                            {
                              OrderLogOperationEnum[
                                log.operation as keyof typeof OrderLogOperationEnum
                              ]
                            }
                          </Td>
                          <Td>
                            {log.success ? (
                              <>
                                <CheckCircleIcon color="green" />
                                &nbsp; Sim
                              </>
                            ) : (
                              <>
                                <WarningTwoIcon color="red" />
                                &nbsp; Não
                              </>
                            )}
                          </Td>
                          <Td>
                            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                            {(log.operationResult as any) &&
                              typeof log.operationResult === 'object' && (
                                <ReactJson
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  src={log.operationResult as any}
                                  enableClipboard={false}
                                />
                              )}
                          </Td>
                          <Td>{maskDateTimeWithSeconds(log.createdAt)}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </SimpleGrid>
                <Divider my="6" />
              </>
            )}
          </Flex>
        )}
      </Box>
    </DefaultLayout>
  );
};
