import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import {
  ReactMultiSelect,
  SelectOption,
} from '../../../../../components/Form/ReactMultiSelect';
import { useAuth } from '../../../../../hooks/auth';
import { DeleteConfirmationModal } from '../../../../../components/DeleteConfirmationModal';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import { showUsersService } from '../../../../../services/Users/ShowUsersService';
import { listUserRolesService } from '../../../../../services/Users/ListUserRolesService';
import { deleteUserAvatarsService } from '../../../../../services/Users/DeleteUserAvatarsService';
import { updateUsersService } from '../../../../../services/Users/UpdateUsersService';
import { updateUserAvatarsService } from '../../../../../services/Users/UpdateUserAvatarsService';

type UpdateUserFormData = {
  name: string;
  email: string;
  bio?: string;
  phone?: string;
  password?: string;
  passwordConfirmation?: string;
  userRoles: SelectOption[];
};

interface ILocationState {
  userId: string;
}

const userUpdateFormSchema = Yup.object().shape({
  bio: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  email: Yup.string()
    .email('E-mail inválido')
    .required('Requerido')
    .transform((value) => value.toLowerCase()),
  name: Yup.string().required('Requerido'),
  password: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  passwordConfirmation: Yup.string()
    .oneOf([null, Yup.ref('password')], 'Senhas não coincidem')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  phone: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  userRoles: Yup.array().min(1).of(Yup.object()).required('Requerido'),
});

export const UserUpdate = (): JSX.Element => {
  const { goBack } = useHistory();
  const toast = useToast();
  const { state } = useLocation<ILocationState>();
  const { updateUser, user } = useAuth();

  const [updatingUser, setUpdatingUser] = useState<UpdateUserFormData>();
  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [userRolesSelectOption, setUserRolesSelectOption] = useState<
    SelectOption[]
  >([]);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  const { register, handleSubmit, formState, reset, control } = useForm({
    resolver: yupResolver(userUpdateFormSchema),
  });

  const { errors } = formState;
  const { userId } = state;

  useEffect(() => {
    async function loadUser(): Promise<void> {
      try {
        const userData = await showUsersService(userId);
        setAvatarUrl(userData.avatarUrl || undefined);

        const parsedUserRoles = userData.userRoles.map((role) => ({
          label: role.name,
          value: role.id,
        }));

        setUpdatingUser({
          email: userData.email,
          name: userData.name,
          bio: userData.bio,
          phone: userData.phone,
          userRoles: parsedUserRoles,
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os dados do usuário, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    async function loadUserRoles(): Promise<void> {
      try {
        const userRoles = await listUserRolesService();

        setUserRolesSelectOption(
          userRoles.map((role) => ({
            label: role.name,
            value: role.id,
          })),
        );
      } catch (err) {
        toast({
          title: 'Falha ao carregar dados',
          description:
            'Ocorreu um erro ao carregar os dados dos cargos do usuário, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }

    loadUserRoles();
    loadUser();
  }, [userId, reset, toast]);

  useEffect(() => {
    reset(updatingUser);
  }, [reset, updatingUser]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteAvatar = useCallback(async () => {
    await deleteUserAvatarsService(userId);

    setAvatar(undefined);
    setAvatarUrl(undefined);
    handleToggleDeleteConfirmationModal();

    if (userId === user.id) {
      delete user.avatar;
      delete user.avatarUrl;

      updateUser(user);
    }
  }, [userId, handleToggleDeleteConfirmationModal, updateUser, user]);

  const handleUpdateUser: SubmitHandler<UpdateUserFormData> = useCallback(
    async ({ userRoles, ...rest }) => {
      try {
        const updatedUser = await updateUsersService({
          userId,
          userRoles: userRoles.map((userRole) => ({
            id: userRole.value,
            name: userRole.label,
          })),
          ...rest,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          const userWithUpdatedAvatar = await updateUserAvatarsService({
            userId,
            avatarData: formData,
          });

          if (userWithUpdatedAvatar.id === user.id) {
            updateUser(userWithUpdatedAvatar);
          }
        } else if (updatedUser.id === user.id) {
          updateUser(updatedUser);
        }

        toast({
          title: 'Editado com sucesso',
          description: 'O usuário foi editado corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        goBack();
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao editar',
            description: 'Ocorreu um erro ao editar o usuário, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [avatar, userId, goBack, toast, updateUser, user.id],
  );

  return (
    <DefaultLayout>
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteAvatar}
      />

      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleUpdateUser)}
      >
        <Heading size="lg" fontWeight="normal">
          Editar usuário
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleToggleDeleteConfirmationModal}
          />
        </Flex>

        <VStack spacing="8">
          <SimpleGrid minChildWidth="240px" spacing="8" w="80%">
            <ReactMultiSelect
              label="Cargos"
              name="userRoles"
              control={control}
              error={errors.userRoles}
              options={userRolesSelectOption}
            />
          </SimpleGrid>

          <MaskedInput
            label="Nome completo"
            error={errors.name}
            {...register('name')}
          />
          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <MaskedInput
              label="E-mail"
              type="email"
              textTransform="lowercase"
              error={errors.email}
              {...register('email')}
            />
            <InternationalPhoneInput
              label="Telefone"
              name="phone"
              control={control}
              error={errors.phone}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <MaskedInput
              as="textarea"
              minHeight="160px"
              resize="none"
              py="2"
              label="Biografia"
              error={errors.bio}
              {...register('bio')}
            />

            <VStack spacing="8">
              <MaskedInput
                type="password"
                label="Senha"
                error={errors.password}
                {...register('password')}
              />
              <MaskedInput
                type="password"
                label="Confirmação de senha"
                error={errors.passwordConfirmation}
                {...register('passwordConfirmation')}
              />
            </VStack>
          </SimpleGrid>
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
