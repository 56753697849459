import { OrderInstallment } from './orderInstallment';
import { RoadPassOrderDetails } from './roadPassOrderDetails';

export enum OrderStatus {
  pending = 'Pendente',
  paid = 'Pago',
  canceled = 'Cancelado',
  processing = 'Processando',
  failed = 'Falha',
  overpaid = 'Pago a mais',
  underpaid = 'Pago a menos',
}

export type OrderBase = {
  paymentProviderOrderId: string;
  amount: number;
  isInternational: boolean;
  closed: boolean;
  code: string;
  currency: string;
  jcoins?: number;
  status:
    | 'pending'
    | 'paid'
    | 'canceled'
    | 'processing'
    | 'failed'
    | 'overpaid'
    | 'underpaid';
  createdAt: string;
  updatedAt: string;
  closedAt?: string;
  externalRecipientName: string;
  externalRecipientDocument: string;
  source?: 'na' | 'app' | 'credit_card';
  installments: number;
  installmentsData?: OrderInstallment[];
  invoiceIssued?: boolean;
  invoiceDate?: string;
  id: string;
  roadPassOrderDetails?: RoadPassOrderDetails;
  cardPaymentProvider?: string;
  isAdditionalCard: boolean;
  isVirtualCard: boolean;
};
