import api from '../api';
import {
  AppBase,
  AppSplitRecipientBankAccountBase,
  AppSplitRecipientBase,
} from '../../models/app';
import { AppLLLoyaltyConfigData } from '../../pages/App/General/Apps/components/AppLLLoyaltyConfigForm';
import { AppRoadPassConfigData } from '../../pages/App/General/Apps/components/AppRoadPassConfigurationForm';
import { AppZoopConfigData } from '../../pages/App/General/Apps/components/AppZoopConfigurationForm';
import {
  CardPaymentProviders,
  JcoinPaymentProviders,
} from '../../pages/App/General/Apps/components/RegisterAppForm';

type BankAccountRequest = {
  account_check_digit: string;
  account_number: string;
  bank: string;
  branch_check_digit?: string;
  branch_number: string;
  holder_document: string;
  holder_name: string;
  holder_type: 'individual' | 'company';
  metadata?: {
    [key: string]: string;
  };
  type: 'checking' | 'savings';
};

type TransferSettings = {
  transfer_enabled: boolean;
  transfer_interval: 'day';
  transfer_day: number;
};

type AnticipationSettings = {
  delay?: string;
  enabled: boolean;
  type: 'full' | '1025';
  volume_percentage: string;
};

type UpdateRecipientProps = {
  id?: string;
  automatic_anticipation_settings?: AnticipationSettings;
  code?: string;
  default_bank_account: BankAccountRequest;
  description?: string;
  document: string;
  email: string;
  metadata?: {
    [key: string]: string;
  };
  name: string;
  transfer_settings?: TransferSettings;
  status: 'active' | 'inactive';
  type: 'individual' | 'company';
};

interface IUpdateAppProps {
  appId: string;
  description?: string;
  hookUrl?: string;
  name: string;
  ref: string;
  splitRecipient: UpdateRecipientProps;
  llloyaltyConfiguration?: AppLLLoyaltyConfigData;
  roadPassConfiguration?: AppRoadPassConfigData;
  zoopConfiguration?: AppZoopConfigData;
  cardPaymentProvider: CardPaymentProviders;
  jcoinPaymentProvider?: JcoinPaymentProviders;
}

interface IDetailedAppSplitRecipient extends AppSplitRecipientBase {
  bankAccount: AppSplitRecipientBankAccountBase;
}

export interface IUpdatedApp extends AppBase {
  splitRecipient: IDetailedAppSplitRecipient;
}

export const updateAppsService = async ({
  appId,
  description,
  hookUrl,
  name,
  ref,
  splitRecipient,
  llloyaltyConfiguration,
  roadPassConfiguration,
  zoopConfiguration,
  cardPaymentProvider,
  jcoinPaymentProvider,
}: IUpdateAppProps): Promise<IUpdatedApp> => {
  const { data } = await api.patch<IUpdatedApp>(`/apps/${appId}`, {
    description,
    hookUrl,
    name,
    ref,
    splitRecipient,
    llloyaltyConfiguration,
    roadPassConfiguration,
    zoopConfiguration,
    cardPaymentProvider,
    jcoinPaymentProvider,
  });

  return data;
};
