import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  useToast,
  ButtonGroup,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { updateRecipientSettingsService } from '../../../../../../../services/Recipients/UpdateRecipientSettingsService';
import { NumberInput } from '../../../../../../../components/Form/NumberInput';

export interface ISettingsModalFormData {
  commission?: number;
}

interface IRecipientSettingsModalProps {
  recipientId: string;
  commission?: number;
  isOpen: boolean;
  onClose: () => void;
  onChangeSettings: (settings: ISettingsModalFormData) => void;
}

const recipientSettingsModalFormSchema = Yup.object().shape({
  commission: Yup.number()
    .typeError('Inválido')
    .integer('Inválido')
    .min(0)
    .max(10000)
    .nullable()
    .transform((value, originalValue) =>
      originalValue === '' ? null : value * 100,
    ),
});

export const RecipientSettingsModal = ({
  recipientId,
  commission,
  isOpen,
  onClose,
  onChangeSettings,
}: IRecipientSettingsModalProps): JSX.Element => {
  const toast = useToast();
  const { register, formState, handleSubmit, reset } = useForm({
    resolver: yupResolver(recipientSettingsModalFormSchema),
  });

  const { errors } = formState;

  useEffect(() => {
    reset({ commission: (commission || 0) / 100 });
  }, [commission, reset]);

  const handleSettingsSubmit: SubmitHandler<ISettingsModalFormData> =
    useCallback(
      async (recipientFormData) => {
        try {
          const updatedRecipient = await updateRecipientSettingsService({
            recipientId,
            ...recipientFormData,
          });

          reset({});

          onChangeSettings({ commission: updatedRecipient.commission });

          toast({
            title: 'Alterado com sucesso',
            description: 'As configurações foram alteradas corretamente',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        } catch (err) {
          toast({
            title: 'Falha ao alterar',
            description:
              'Ocorreu um erro ao alterar configurações, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      },
      [recipientId, onChangeSettings, reset, toast],
    );

  const handleCloseModal = useCallback(() => {
    onClose();
    reset({});
  }, [onClose, reset]);

  return (
    <Modal size="xl" isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleSettingsSubmit)}>
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Configurações
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <NumberInput
            label="Comissão"
            precision={2}
            error={errors.commission}
            {...register('commission')}
          />
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={handleCloseModal}>
              Cancelar
            </Button>

            <Button colorScheme="green" type="submit">
              Salvar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
