import { Box, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import * as Yup from 'yup';
import { MaskedInput } from '../../../../../../components/Form/MaskedInput';

export type AppLLLoyaltyConfigData = {
  clientId: string;
  clientSecret: string;
  grantType: string;
};

interface IAppLLLoyaltyConfigProps {
  requireFields?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSchemaChange: (schema: Yup.ObjectSchema<any>) => void;
  formHook: UseFormReturn;
}

export type RegisterLLLoyaltConfigurationFormData = {
  clientId: string;
  clientSecret: string;
  grantType: string;
};

const llloyaltyConfigFormSchema = Yup.object().shape({
  clientId: Yup.string().required('Requerido'),
  clientSecret: Yup.string().required('Requerido'),
  grantType: Yup.string().required('Requerido'),
});

export const AppLLLoyaltyConfigForm = ({
  requireFields,
  onSchemaChange,
  formHook,
}: IAppLLLoyaltyConfigProps): JSX.Element => {
  const { register, formState } = formHook;

  const { errors: errorsform } = formState;

  const errors = errorsform.llloyaltyConfiguration;

  useEffect(() => {
    onSchemaChange(
      requireFields ? llloyaltyConfigFormSchema : Yup.object().shape({}),
    );
  }, [requireFields]);

  return (
    <Box>
      <VStack spacing="8">
        <MaskedInput
          label="Client ID"
          error={errors?.clientId}
          {...register('llloyaltyConfiguration.clientId')}
        />
        <MaskedInput
          label="Client Secret"
          error={errors?.clientSecret}
          {...register('llloyaltyConfiguration.clientSecret')}
        />
        <MaskedInput
          label="Grant Type"
          error={errors?.grantType}
          {...register('llloyaltyConfiguration.grantType')}
        />
      </VStack>
    </Box>
  );
};
