import { Box, Heading, Flex, useToast, Icon } from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { LinkButton } from '../../../../../components/LinkButton';
import { maskShortDate } from '../../../../../utils/formatters/handleMask';
import { IUser, UserTable } from './components/UserTable';
import { listUsersService } from '../../../../../services/Users/ListUsersService';

interface IFilter {
  filter: string;
  value: string;
}

interface ILoadUsersProps {
  name?: string;
  sort?: 'name' | 'phone' | 'email' | 'createdAt';
  order?: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
}

export const UserList = (): JSX.Element => {
  const toast = useToast();

  const [usersList, setUsersList] = useState<IUser[]>([]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentSort, setCurrentSort] = useState<
    'name' | 'phone' | 'email' | 'createdAt'
  >();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();

  const loadUsers = useCallback(
    async ({
      name,
      page,
      sort = 'createdAt',
      order = 'ASC',
    }: ILoadUsersProps) => {
      try {
        const users = await listUsersService({
          name: name || undefined,
          page,
          sort,
          order,
        });

        const usersWithformattedDate = users.items.map((user) => ({
          ...user,
          formattedDate: maskShortDate(user.createdAt),
        }));

        setTotalPages(users.pages);
        setUsersList(usersWithformattedDate);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os dados dos usuários, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  const handleFilter = useCallback(
    ({ filter, value }: IFilter) => {
      setCurrentFilters({ [filter]: value });

      loadUsers({ [filter]: value });
    },
    [loadUsers],
  );

  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback(
    (sort: 'name' | 'phone' | 'email' | 'createdAt') => {
      setCurrentSort(sort);
    },
    [],
  );

  const handleOrderList = useCallback(
    (order: 'ASC' | 'DESC') => {
      loadUsers({
        ...currentFilters,
        page: currentPage,
        sort: currentSort,
        order,
      });
    },
    [currentFilters, currentPage, currentSort, loadUsers],
  );

  useEffect(() => {
    loadUsers({
      ...currentFilters,
      page: currentPage,
      sort: currentSort,
    });
  }, [currentFilters, currentPage, currentSort, loadUsers]);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Usuários
          </Heading>

          <LinkButton
            colorScheme="green"
            icon={<Icon as={RiAddLine} fontSize="16" />}
            to="/users/register"
          >
            Cadastrar novo
          </LinkButton>
        </Flex>

        <UserTable
          mt="4"
          users={usersList}
          onFilter={debounceHandleFilter}
          defaultFilterBy="name"
          defaultSortBy="createdAt"
          onSort={handleSortList}
          onOrder={handleOrderList}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
