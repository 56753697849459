import { Box, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import * as Yup from 'yup';
import { MaskedInput } from '../../../../../../components/Form/MaskedInput';

export type AppZoopConfigData = {
  user: string;
  password: string;
  marketplaceId: string;
  mainStoreZoopId: string;
};

interface IAppZoopConfigProps {
  requireFields?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSchemaChange: (schema: Yup.ObjectSchema<any>) => void;
  formHook: UseFormReturn;
}

export type RegisterZoopConfigurationFormData = {
  user: string;
  password: string;
  marketplaceId: string;
  mainStoreZoopId: string;
};

const zoopConfigFormSchema = Yup.object().shape({
  user: Yup.string().required('Requerido'),
  password: Yup.string().required('Requerido'),
  marketplaceId: Yup.string().required('Requerido'),
  mainStoreZoopId: Yup.string().required('Requerido'),
});

export const AppZoopConfigForm = ({
  requireFields,
  formHook,
  onSchemaChange,
}: IAppZoopConfigProps): JSX.Element => {
  const { register, formState } = formHook;

  const { errors: errorsform } = formState;

  const errors = errorsform.zoopConfiguration;

  useEffect(() => {
    onSchemaChange(
      requireFields ? zoopConfigFormSchema : Yup.object().shape({}),
    );
  }, [requireFields]);

  return (
    <Box>
      <VStack spacing="8">
        <MaskedInput
          label="User"
          error={errors?.user}
          {...register('zoopConfiguration.user')}
        />

        <MaskedInput
          label="Password"
          error={errors?.password}
          {...register('zoopConfiguration.password')}
        />

        <MaskedInput
          label="Id Marketplace"
          error={errors?.marketplaceId}
          {...register('zoopConfiguration.marketplaceId')}
        />
        <MaskedInput
          label="ID da Main Store"
          error={errors?.mainStoreZoopId}
          {...register('zoopConfiguration.mainStoreZoopId')}
        />
      </VStack>
    </Box>
  );
};
