import { useLocation } from 'react-router-dom';
import {
  Box,
  Heading,
  Flex,
  Text,
  SimpleGrid,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { maskDate } from '../../../../../utils/formatters/handleMask';
import {
  IDetailedCustomer,
  showCustomersService,
} from '../../../../../services/Customers/ShowCustomersService';
import {
  CustomerDocumentType,
  CustomerType,
} from '../../../../../models/customer';

interface ICustomer extends IDetailedCustomer {
  formattedDate: string;
}

interface ILocationState {
  customerId: string;
}

export const CustomerDetails = (): JSX.Element => {
  const toast = useToast();
  const {
    state: { customerId },
  } = useLocation<ILocationState>();

  const [customer, setCustomer] = useState<ICustomer>();

  useEffect(() => {
    async function loadCustomer(): Promise<void> {
      try {
        const customerData = await showCustomersService(customerId);

        const customerWithformattedDate = {
          ...customerData,
          formattedDate: maskDate(customerData.createdAt),
        };

        setCustomer(customerWithformattedDate);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os detalhes do comprador, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadCustomer();
  }, [customerId, toast]);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Heading size="lg" fontWeight="normal">
          Detalhes do comprador
        </Heading>

        {customer && (
          <Flex direction="column">
            <Flex align="center" direction="column">
              <Text fontSize="4xl" fontWeight="bold" mt="4">
                {customer.name}
              </Text>
            </Flex>

            <SimpleGrid
              minChildWidth="240px"
              spacing="8"
              px="8"
              mt="12"
              w="100%"
            >
              <Text>
                App:
                <Text as="span" color="gray.500" ml="2">
                  {customer.app.name}
                </Text>
              </Text>

              <Text>
                Tipo do cliente:
                <Text as="span" color="gray.500" ml="2">
                  {CustomerType[customer.type]}
                </Text>
              </Text>

              <Text>
                Documento:
                <Text as="span" color="gray.500" ml="2">
                  {customer.document}
                </Text>
              </Text>

              <Text>
                Tipo do documento:
                <Text as="span" color="gray.500" ml="2">
                  {
                    CustomerDocumentType[
                      customer.documentType.toUpperCase() as keyof typeof CustomerDocumentType
                    ]
                  }
                </Text>
              </Text>

              <Text>
                E-mail:
                <Text as="span" color="gray.500" ml="2">
                  {customer.email}
                </Text>
              </Text>
            </SimpleGrid>
          </Flex>
        )}
      </Box>
    </DefaultLayout>
  );
};
