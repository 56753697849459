import { Box, Heading, Flex, useToast } from '@chakra-ui/react';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { maskShortDate } from '../../../../../utils/formatters/handleMask';
import { CustomerTable, ICustomer } from './components/CustomerTable';
import { listCustomersService } from '../../../../../services/Customers/ListCustomersService';

interface IFilter {
  filter: string;
  value: string;
}

interface ILoadCustomersProps {
  appName?: string;
  email?: string;
  name?: string;
  sort?: 'name' | 'email' | 'createdAt';
  order?: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
}

export const CustomerList = (): JSX.Element => {
  const toast = useToast();

  const [customersList, setCustomersList] = useState<ICustomer[]>([]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentSort, setCurrentSort] = useState<
    'name' | 'email' | 'createdAt'
  >();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();

  const loadCustomers = useCallback(
    async ({
      appName,
      name,
      email,
      page,
      sort = 'createdAt',
      order = 'ASC',
    }: ILoadCustomersProps) => {
      try {
        const customers = await listCustomersService({
          appName: appName || undefined,
          name: name || undefined,
          email: email || undefined,
          page,
          sort,
          order,
        });

        const customersWithformattedDate = customers.items.map((customer) => ({
          ...customer,
          formattedDate: maskShortDate(customer.createdAt),
        }));

        setTotalPages(customers.pages);
        setCustomersList(customersWithformattedDate);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os dados dos compradores, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  const handleFilter = useCallback(
    ({ filter, value }: IFilter) => {
      setCurrentFilters({ [filter]: value });

      loadCustomers({ [filter]: value });
    },
    [loadCustomers],
  );

  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback((sort: 'name' | 'email' | 'createdAt') => {
    setCurrentSort(sort);
  }, []);

  const handleOrderList = useCallback(
    (order: 'ASC' | 'DESC') => {
      loadCustomers({
        ...currentFilters,
        page: currentPage,
        sort: currentSort,
        order,
      });
    },
    [currentFilters, currentPage, currentSort, loadCustomers],
  );

  useEffect(() => {
    loadCustomers({
      ...currentFilters,
      page: currentPage,
      sort: currentSort,
    });
  }, [currentFilters, currentPage, currentSort, loadCustomers]);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Compradores
          </Heading>
        </Flex>

        <CustomerTable
          mt="4"
          customers={customersList}
          onFilter={debounceHandleFilter}
          defaultFilterBy="name"
          defaultSortBy="createdAt"
          onSort={handleSortList}
          onOrder={handleOrderList}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
