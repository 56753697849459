export enum RecipientType {
  company = 'Pessoa Jurídica',
  individual = 'Pessoa Física',
}

export enum RecipientBankAccountType {
  checking = 'Conta Corrente',
  savings = 'Conta Poupança',
}

export type RecipientBankAccountBase = {
  paymentProviderBankAccountId: string;
  accountCheckDigit: string;
  accountNumber: string;
  bank: string;
  branchCheckDigit?: string;
  branchNumber: string;
  holderDocument: string;
  holderName: string;
  holderType: 'company' | 'individual';
  type: 'checking' | 'savings';
  createdAt: string;
};

export type RecipientBase = {
  paymentProviderRecipientId: string;
  commission?: number;
  description?: string;
  document: string;
  email: string;
  name: string;
  type: 'company' | 'individual';
  createdAt: string;
};

export type CustomerBase = {
  name: string;
  email: string;
  document: string;
};
